import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import DTH_Logo from '../assets/images/DTHLogo.png';
import { RedTheme } from '../global/theme';
import { UserMenu } from '../navigation/userMenu';
export const Header = () => {
    let redtheme: any =RedTheme;
    return (
      <>
        {/** Sticky header appbar */}
        <AppBar
          className="tap-appbar-header1"
          position="absolute"
          sx={{
            bgcolor: redtheme.palette.appBar.white,
            top: '8px',
            left: '8px',
            width: 'calc(100% - 16px)',
            borderRadius: '8px',
            boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: {
                xs: '0px 10px',
                sm: '0px 10px',
                md: '0px 42px',
                lg: '0px 72px',
                xl: '0px 72px',
              },
              height: '64px',
              background: redtheme.palette.box.white,
            }}
          >
            {/** App Logo */}
            <Box
              sx={{
                position: 'relative',
                width: '50%',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <img src={DTH_Logo} className="dth-logo" title="DTH" alt="DTH" height="42px" width="172px" />
            </Box>
            {/** Logged In Profile User */}
            <Box
              sx={{
                flexGrow: 0,
                width: '50%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <UserMenu></UserMenu>
            </Box>
          </Box>
        </AppBar>
      </>
    );
}
