import { CSSProperties, FC, Fragment, useRef, useState } from 'react';
import { applicationsActions } from '../redux/slices/applicationSlice';
import { type SxProps, type PopoverOrigin, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { checkForAppAccess, createAppUrl } from '../utils/helpers';
import { useAppDispatch } from '../hooks';
import { useModal } from '../context/ModalContext';
import UnauthorizeWarning from './UnauthorizeWarning';
import { Application } from '../types/response';
import { OK, UNAUTHORIZED_MESSAGE } from '../constants/dthConstants';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIcon } from '../assets/images/Info_circle.svg';


export interface AppPopupProps {
  paperStyles?: SxProps;
  appContainerStyles?: SxProps;
  logoContainerStyles?: SxProps;
  logoStyles?: CSSProperties;
  appLabelStyles?: SxProps;
  descriptionStyles?: SxProps;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

export interface ApplicationCardProps {
  app: Application;
  disablePopup?: boolean;
  containerClass?: string;
  imageOverlayClass?: string;
  imageClass?: string;
  labelClass?: string;
  popupProps?: AppPopupProps;
}

const ApplicationCard: FC<ApplicationCardProps> = ({
  app,
  disablePopup = true,
  containerClass = '',
  imageOverlayClass = '',
  imageClass = '',
  labelClass = '',
  popupProps = {},
}) => {
  const dispatch = useAppDispatch();
  const { initiate, close } = useModal();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate(); 
  const isSmallScreen = useMediaQuery('(max-width:960px)'); // md breakpoint

  const handleClose = () => setAnchorEl(null);

  const openApp = () => {
    dispatch(applicationsActions.setApplicationClicked(app.key)); 
    if (app.roleRestricted && !checkForAppAccess(app.key))
      return navigate('/error'); 
    dispatch(applicationsActions.handleRecents(app));
     window.open(createAppUrl(app.url), '_blank');
  };

  const isPopupOpen = Boolean(anchorEl);
  const { logoStyles = {} } = popupProps;

  const imageStyles = {
    height: '69px',
    width: '75px',
    '@media (min-width:640px) and (max-width:1024px)': { 
      width: '69.3px',
      height: '64px',
    },
    ...logoStyles,
  };

  return (
    <Fragment>
      <div
        className={`space-y-2 cursor-pointer flex flex-col items-center ${containerClass}`}
        ref={ref}
        onClick={() => disablePopup && openApp()}
        onMouseOver={() => !disablePopup && setAnchorEl(ref.current)}
      >
      <div
          className={`-:px-6 -:py-7 -:rounded-2xl -:bg-white -:shadow-appCard -:flex -:justify-center -:items-center -:w-fit  xl:w-[156px] xl:h-[156px] ${imageOverlayClass}`}
          style={{ position: 'relative' }}
        >
          {isSmallScreen && (
            <Box
              sx={{
                position: 'absolute',
                top: 4,
                right: -4,
                width: 24,
                height: 24,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(ref.current);
              }}
            >
              <InfoIcon />
            </Box>
          )}
        <img src={app.imagePath} alt={app.label} className={`-:h-[88px] -:w-[95px]   xl:w-[95.28px] xl:h-[88px]${imageClass}`} />
        </div>
       <p className={`-:text-base/4 -:font-jnjTRegular -:font-normal -:text-center -:text-black ${labelClass}`} style={{ marginInline: 'auto' }}>
          {app.label}
        </p>
      </div>
      <Popover
        open={isPopupOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        onMouseLeave={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left', ...(popupProps.anchorOrigin || {}) }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left', ...(popupProps.transformOrigin || {}) }}
        slotProps={{
          paper: {
            onClick: openApp,
            onMouseLeave: handleClose,
            sx: {
              width: '272px',
              Height: '318px',
              padding: '16px',
              backgroundColor: '#004685',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '8px',
              borderRadius: '16px',
              cursor: 'pointer',
              '@media (min-width:640px) and (max-width:1024px)': { 
                Width: '272px',
                Height: '318px',
                padding: '16px',
              },
              ...(popupProps.paperStyles || {}),
            },
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            ...(popupProps.appContainerStyles || {}),
          }}
        >
         <Box
            sx={{
              width: '91px',
              height: '91px',
              padding: '24px',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '16px',
              marginBottom: '8px',
              '@media (min-width:640px) and (max-width:1024px)': { 
                Width: '94px',
                Height: '94px',
                padding: '16px',
              },
              ...(popupProps.logoContainerStyles || {}),
            }}
          >
            <Box component="img" src={app.imagePath} alt={app.label} sx={imageStyles} />
         </Box>
          <Box
            component="p"
            sx={{
              fontSize: '16px',
              lineHeight: '16px',
              fontWeight: 500,
              fontFamily: 'JohnsonText-Regular, sans-serif',
              textAlign: 'center',
              color: 'white',
              ...(popupProps.appLabelStyles || {}),
            }}
          >
            {app.label}
          </Box>
        </Box>
        <Box
          component="p"
          sx={{
            fontSize: '14px',
            lineHeight: '16px',
            fontFamily: 'JohnsonText-Regular, sans-serif',
            textAlign: 'center',
            color: 'white',
            fontWeight: 500,
            ...(popupProps.descriptionStyles || {}),
          }}
        >
          {app.description}
        </Box>
      </Popover>
    </Fragment>
  );
};

export default ApplicationCard;
